import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Page from '../components/Page';
import { initializeApollo } from '../lib/apolloClient';
import { GET_LIVE_DATA } from '../utils/queries/live';

export default function Live({ liveFromServer, errorFromServer, liveId }) {
  return (
    <Page
      contentFromServer={liveFromServer}
      errorFromServer={errorFromServer}
      liveId={liveId}
    />
  );
}

export async function getStaticProps(ctx) {
  const liveId = 'clowz5qqi008pkg01zkgmmpnn';
  // const liveId  = "clptoe2pq00w3lf018jmaulam";

  const apolloClient = initializeApollo();
  try {
    const { data } = await apolloClient.query({
      query: GET_LIVE_DATA,
      variables: { liveId }
    });

    return {
      props: {
        liveFromServer: data.live,
        liveId,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  } catch (error) {
    console.log('error', error?.graphQLErrors[0]);
    return {
      props: {
        errorFromServer: error?.graphQLErrors[0].extensions,
        ...(await serverSideTranslations(ctx.locale, ['common']))
      },
      revalidate: 600 // 10 minutes
    };
  }
}

// export async function getStaticPaths() {
//   // if (process.env.NODE_ENV === 'production') {
//   //   const myHeaders = new Headers();
//   //   myHeaders.append('Authorization', process.env.BACKEND_MANAGER_TOKEN);
//   //   myHeaders.append('Content-Type', 'application/json');

//   //   const graphql = JSON.stringify({
//   //     query: 'query { activeLives{ id } }',
//   //     variables: {}
//   //   });

//   //   const requestOptions = {
//   //     method: 'POST',
//   //     headers: myHeaders,
//   //     body: graphql,
//   //     redirect: 'follow'
//   //   };

//   //   const res = await fetch(process.env.NEXT_PUBLIC_BACKEND_API, requestOptions);
//   //   const data = await res.json();

//   //   const paths = data.data.activeLives.map((live) => ({
//   //     params: { liveId: live.id }
//   //   }));

//   //   return { paths, fallback: 'blocking' };
//   // } else {
//   const paths = [];
//   return { paths, fallback: 'blocking' };
//   // }
// }
